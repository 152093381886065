<template>
  <div>
    <div id="navbar" class="w-100 navbar-container">
      <div class="line"></div>
      <div class="links-container d-md-flex">
        <p
          class="text-uppercase lato-regular view-area-link"
          role="button"
          @click="goToSection('#anchor')"
        >
            The Building
        </p>
        <p
          class="text-uppercase lato-regular view-area-link"
          v-scroll-to="{ el: '#views', offset: -100 }"
          role="button"
        >
          <nuxt-link :to="{ path: '/', hash:'#views'}" style="color:#212529" v-scroll-to="{ el: '#views', offset: -100 }">
            The Views
          </nuxt-link>
        </p>
        <p
          class="text-uppercase lato-regular view-area-link"
          v-scroll-to="{ el: '#area', offset: -100 }"
          role="button"
        >
          The Area
        </p>

        <p
          class="text-uppercase lato-regular view-area-link"
          role="button"
        >
          <a href="/contact" style="color:#212529">Contact</a>
        </p>
      </div>
      <div class="text-center">
        <b-navbar-brand to="/" class="logo-container bg-grey" exact>
          <img
            src="/img/logo_nav.png"
            alt="The Hill Logo"
            fluid
            class="navbarLogo"
          />
        </b-navbar-brand>
      </div>
      <div class="register-container">
        <p
          class="freight-big-pro-book-italic-bold text-brown register-link"
          v-scroll-to="{ el: '#registerForm', offset: -100 }"
          role="button"
        >
          Register Now&nbsp;...
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  data() {
    return {
      fadeInElement_nav: null,
      testAnim: null,
    };
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    this.initNavElReveal_homePage();
    
  },
  methods: {
    goToSection(el) {
      var _this = this;
      this.$router.push('/');
      setTimeout(() => {
        _this.$scrollTo(el)
      }, 1000)
    },
    checkQuery() {
      this.query = this.$route.query;
      let hash = this.$route.query["goto"];
      console.log(hash);
      if (hash != undefined) {
        this.$root.$emit("scrolltoSection", {
          place: hash,
        });
      }
    },
    goToId(id) {
      let adjuster = 120;
      var offsetT = $("#" + id).offset();
      // console.log("offsetT: "+offsetT);
      if (offsetT != undefined) {
        $("html, body").animate(
        {
            scrollTop: offsetT.top - adjuster,
        },
        500
        );
      }
    },
    initNavElReveal_homePage() {
      let testAnim = this.testAnim;
      testAnim = gsap;
      var scrollTimeline = testAnim
        .timeline({ paused: true })
        .set("#navbar", {
          opacity: 0,
        })
        .to("#navbar", {
          opacity: 1,
          duration: 0.3,
        });
      this.testAnimTrigger = ScrollTrigger.create({
        trigger: ".img-register-container",
        start: "top bottom",
        end: "+=30%",
        animation: scrollTimeline,
        scrub: 0.3,
      });
    },
    beforeDestroy() {
      setTimeout(() => {
        ScrollTrigger.getAll().forEach((t) => t.kill());
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

#navbar {
  padding: 10px 0px 10px 0px;
  opacity: 0;
  z-index: 1030;
  position: relative;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $m-grey;
  -webkit-box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.36);
  .links-container {
    position: absolute;
    width: 100%;
    left: 10%;
    top: 18px;
    p {
      font-size: 12px;
      padding-right: 30px;
    }
    @media screen and (max-width: 500px) {
      display: none;
      position: relative;
  }
  }
  .register-container {
    position: absolute;
    right: 100px;
    top: 15px;
    p {
      margin: 0;
      font-size: 15px;
    }
    .register-link{
      background-color: $m-brown;
      color: $m-grey;
      padding: 0 1rem;
      border:solid 0px $m-brown;
      border-radius: 15px;
    }
  }

  .line {
    position: absolute;
    padding: 0;
    top: 39px;
    border-bottom: 1.5px solid $m-blue;
    width: 100%;
  }
  .logo-container {
    width: 250px;
    z-index: 99999;
    position: relative;
    margin: 0;
  }
  .navbarLogo {
    width: 100%;
    max-width: 200px;
    cursor: pointer;
  }
  @media screen and (max-width: 1000px) {
    .links-container {
      left: 5%;
    }
  }
  @media screen and (max-width: 850px) {
    .navbarLogo {
      max-width: 180px;
    }
    .logo-container {
      width: 200px;
    }
    .register-container {
      right: 50px;
    }
    .links-container {
      left: 0px;
    }
  }
  @media screen and (max-width: 630px) {
    height: 60px;
    .navbarLogo {
      max-width: 122px;
    }
    .logo-container {
      width: 131px;
    }
    .register-container {
      right: 10px;
      top: 19px;
      p {
        font-size: 11.8px;
      }
    }
    .links-container {
      left: 10px;
      top: 55px;
      p {
        font-size: 10.5px;
        padding-right: 10px;
      }
    }
  }
}
</style>
